import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Illustration for Designers`}</h2>
    <p>{`Designers use illustrations to make it easier for the user to understand their task or what they need to do. Overall, adding illustrations to the user interface will enhance the user experience. Illustrations can clarify a complex idea, deliver important information, or captivate the user’s attention. As well as any other part of an interface, illustration is a functional element rather than decoration`}</p>
    <h2>{`Various Illustrations Type`}</h2>
    <p>{`Due to its function, illustration rockets with a constantly growing number of websites and mobile apps as one of the ways to enhance usability, emotional and visual appeal of the interface. Let’s get to know more about various illustration types.`}</p>
    <h3>{`Graphicon`}</h3>
    <p>{`A simple type of illustration just like an icon. Icons are small designs with a high functional impact. Icon allows you to condense a large quantity of information into a minimal form of expression.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/illus-graphicon.svg",
      "alt": "graphicon illustration"
    }}></img>
    <h3>{`Character`}</h3>
    <p>{`Object illustrations that use human characters. Supports designs with expressive and relatable visualizations. Character design aims to make these characters engaging, visually stimulating, and memorable.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/illus-character.svg",
      "alt": "character illustration"
    }}></img>
    <h3>{`Spot`}</h3>
    <p>{`A spot illustration is an object that stands on its own, without a background scene. We use this type of illustration to highlight featured areas on home pages, and add personality throughout the site. This illustration depicts a situation you want to convey but not as dominant, and the dimensions are not too large.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/illus-spot.svg",
      "alt": "spot illustration"
    }}></img>
    <h3>{`Hero`}</h3>
    <p>{`A hero image is a large and eye-catching graphic, prominently placed at the top of a website or other designed page. It offers a first glimpse of a product, a setup for a story, context for a brand.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/illus-hero.svg",
      "alt": "hero illustration"
    }}></img>
    <div className="divi" />
    <h2>{`Where Illustrations are Needed`}</h2>
    <p>{`Here are some UX parts where illustrations are needed to help the user:`}</p>
    <h3>{`Empty State`}</h3>
    <p>{`Empty state is a moment in a user’s experience when interacting with a product where there is “nothing” to display. The illustrations in the empty state help enhance the user experience and as additional information so that users will know what they should do in the empty state.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/illus-empty-state.svg",
      "alt": "empty state illustration"
    }}></img>
    <h3>{`Onboarding`}</h3>
    <p>{`The onboarding screens are the first things that the users see when they launch your application. It is usually used to demonstrate some facts about the application such as how to use it, the application features, and how the application can help the users. Too many steps in the onboarding are likely to be skipped, especially if they were designed poorly. So, illustration can help users to understand the message or information faster. Make it short and sweet and display only the main features of your applications.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/illus-onboarding.svg",
      "alt": "onboarding illustration"
    }}></img>
    <h3>{`Interactivity`}</h3>
    <p>{`Interactivity is focused on the moment when a user interacts with a product. For designers, the moment of interaction is just a part of the journey that a user goes through when they interact with a product. Illustration plays a significant role in the experience and seamlessness of the user’s interaction with the product.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/illus-interactivity.svg",
      "alt": "interativity illustration"
    }}></img>
    <h3>{`Popup`}</h3>
    <p>{`A popup is a window that automatically appears (“pops up”) on a website while the user is browsing. Popups are used for many reasons, including sharing discounts and notifications, showing errors, collecting visitor information. Illustration can help users to understand the popup’s message faster.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/illus-popup.svg",
      "alt": "popup illustration"
    }}></img>
    <h3>{`Banner`}</h3>
    <p>{`A banner displays an important, succinct message, and provides actions for users to address (or dismiss the banner). It requires a user action to be dismissed. The banner message communicates a change or error within an app. Banners should be considered as part of an overall in-app messaging strategy supported by suitable illustration.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/illus-banner.svg",
      "alt": "banner illustration"
    }}></img>
    <h3>{`Menu`}</h3>
    <p>{`Not only do icons make a navigation menu easier to use, but they also make it more individualized and enjoyable. Also, when used correctly, they can significantly improve the overall visual appeal.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/illus-menu.svg",
      "alt": "menu illustration"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      